<template>
  <div class="">
    <div class="flex justify-center flex-1">
      <svg
        class="w-16 h-16 text-custom-green animate-spin"
        xmlns="http://www.w3.org/2000/svg"
        v-if="isLoading"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          class="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          stroke-width="4"
        ></circle>
        <path
          class="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    </div>

    <div v-if="!isLoading" class="flex flex-col gap-10 md:flex-row">
      <div class="w-full p-6 bg-white rounded shadow md:w-1/4">
        <div class="flex flex-col items-center justify-center">
          <img
            v-if="profile?.photo_url"
            :src="profile?.photo_url"
            class="object-cover object-center w-40 h-40 rounded-full"
          />
          <img
            v-else
            src="http://placehold.it/200?text=HuskyTask"
            class="rounded-full"
          />
          <div class="flex flex-col items-center w-full my-4">
            <h3 class="text-2xl font-bold capitalize">
              {{ profile?.first_name }} {{ profile?.last_name }}
            </h3>
      <div class="py-4">
              <vue3-star-ratings :showControl="false" :disableClick="true" v-model="rating" />
      </div>

            <p>{{ profile?.bio }}</p>
          </div>
          <div
            class="flex justify-between flex-1 w-full p-4 mb-4 bg-gray-100 rounded-sm "
          >
          <p class="w-full text-xl font-bold text-center text-custom-green" v-if="profile?.is_volunteer">Volunteer</p>
          <template v-else>
            <p class="text-xl font-bold text-gray-800">Rate</p>
            <p class="text-xl font-bold text-custom-green">
              ${{ profile?.hour_rate }}/Hr
            </p>
            </template>
          </div>
          <div class="flex flex-col w-full my-4 rounded-sm">
            <p class="text-xl font-bold text-gray-800">Location</p>
            <p class="">
              {{ profile?.city }}, {{ profile?.province }} -
              <span class="uppercase"> {{ profile?.postal_code }}</span>
            </p>

              <!-- @click="$refs.chat.sendMessage()" -->
            <!-- <button
            @click="messageInputToggle=true"
              :disabled="isSendMessageLoading"
              type="button"
              class="flex items-center justify-center px-6 py-3 mt-10 mb-2 text-xl font-bold tracking-wide text-white uppercase rounded-sm shadow bg-custom-green-dark"
            >
              <span> Send Message</span>
            </button> -->
          </div>
        </div>
      </div>
      <div class="w-full p-6 bg-white rounded shadow md:w-3/4">
        <h3 class="mb-5 text-2xl font-bold uppercase">Services</h3>
        <div class="grid grid-cols-2 gap-1 md:gap-4 md:grid-cols-3 xl:grid-cols-4">
          <div
            class="
              px-1 md:px-4
              py-2.5
            "
            v-for="(service, index) in servicesList"
            :key="index"
            :id="service.id"
          >
            <button type="button" class="w-full h-full break-words px-2 py-1  xl:text-xl font-semibold border-4" v-html="cleanText(service.name)"></button>
          </div>
        </div>
        <div class="flex flex-col w-full p-4">
          <label-text-input v-model="message" label="Send Message"/>
          <button
            @click="sendMessage"
              :disabled="isSendMessageLoading"
              type="button"
              class="flex items-center justify-center w-auto px-6 py-3 mt-10 mb-2 text-xl font-bold tracking-wide text-white uppercase rounded-sm shadow max-w-min bg-custom-green-dark"
            >
              <svg
                v-if="isSendMessageLoading"
                class="w-8 h-8 mr-3 -ml-1 text-white animate-spin"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  class="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  stroke-width="4"
                ></circle>
                <path
                  class="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              <span> Send </span>
            </button>
        </div>
        <!-- <chat ref="chat" :selected-user="selectedUser" :currentUser="currentUser" /> -->
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from '@vue/reactivity'
import { onMounted } from '@vue/runtime-core'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import LabelTextInput from '../components/LabelTextInput.vue'
import vue3StarRatings from 'vue3-star-ratings'
// import Chat from '../components/Chat.vue'
export default {
  components: {
    LabelTextInput,
    vue3StarRatings
  },
  setup () {
    const router = useRouter()
    const store = useStore()
    const isSendMessageLoading = ref(false)
    const messageInputToggle = ref(false)
    const message = ref('')
    const rating = ref(0)
    const profile = ref(null)
    const servicesList = ref([])
    const isLoading = ref(true)

    const currentUser = ref({})
    const selectedUser = ref({})
    onMounted(async () => {
      // selectedUser.value =
      currentUser.value = await store.getters['auth/getUser'] // ("data/getUserProfile");
      // console.log(currentUser.value)
      profile.value = await store.getters['data/getUserProfile'] // ("data/getUserProfile");
      selectedUser.value = profile.value
      if (!Object.keys(profile.value).length) {
        router.push({
          name: 'Search'
        })
      }
      // setup rating
      rating.value = profile.value.avg_rating
      // console.log('profileData', profile.value)

      isLoading.value = false
      const servicesData = await store.state.data.services
      for (const key in servicesData) {
        if (Object.hasOwnProperty.call(servicesData, key)) {
          const categories = servicesData[key]
          for (const key in categories) {
            if (Object.hasOwnProperty.call(categories, key)) {
              const category = categories[key]
              // console.log(category.id, category)
              if (profile.value.services.includes(category.id)) {
                if (
                  !servicesList.value.includes(category.id) ||
                  !servicesList.value.includes(category.name)
                ) {
                  servicesList.value.push(category)
                }
              }
            }
          }
        }
      }
    })
    const sendMessage = async () => {
      isSendMessageLoading.value = true
      const res = await store.dispatch('data/sendMessage', { message: message.value, selectedUser: selectedUser.value.user, user: currentUser.value.id })
      console.log(res, message, currentUser.value)
      isSendMessageLoading.value = false
      message.value = ''
      alert(
        'Your message has been send successfully, Thanks!'
      )
    }
    const cleanText = (word) => {
      console.log(word)
      return (word.replace(/&nbsp;/g, ' ')).replace(/\u00A0/g, ' ')
    }

    return {
      cleanText,
      profile,
      isLoading,
      servicesList,
      messageInputToggle,
      message,
      sendMessage,
      selectedUser,
      isSendMessageLoading,
      currentUser,
      rating
    }
  }
}
</script>
